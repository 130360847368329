import { ServerSentEventMessage, events } from 'fetch-event-stream';
import * as React from 'react';

interface MutationOptions<TVariables = unknown> {
	mutationFn: (variables: TVariables) => Promise<Response>;
	onError?: (error: Error) => void;
	onEvent: (event: ServerSentEventMessage, variables: TVariables) => void;
	onSuccess?: () => void;
}

export function useStreamingResponseMutation<TVariables = unknown>({
	mutationFn,
	onError,
	onEvent,
	onSuccess,
}: MutationOptions<TVariables>) {
	const [status, setStatus] = React.useState<'idle' | 'loading' | 'streaming' | 'error'>('idle');
	const [error, setError] = React.useState<Error | null>(null);
	const mutate = async (params: TVariables) => {
		setStatus('loading');
		setError(null);
		try {
			const response = await mutationFn(params);
			setStatus('streaming');
			const stream = events(response);
			for await (const event of stream) {
				onEvent(event, params);
			}
			setStatus('idle');
			onSuccess?.();
		} catch (e) {
			const err = e as Error;
			setError(err);
			setStatus('error');
			onError?.(err);
		}
	};
	const isLoading = status === 'loading';
	const isStreaming = status === 'streaming';
	const isError = status === 'error';
	return { mutate, isLoading, isStreaming, isError, error };
}
