import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

const BASE_QUERY_KEY = `/aiconversation/<id>/chatMessage`;

interface QueryParams {
	id: string;
	impersonationContext?: Api.IImpersonationContext;
	enabled?: boolean;
}

export function getConversationChatMessageQueryKey(queryParams: QueryParams): any[] {
	return [BASE_QUERY_KEY, queryParams.id, queryParams.impersonationContext];
}

export function useAIConversationChatMessageQuery(queryParams: {
	id: string;
	impersonationContext?: Api.IImpersonationContext;
	enabled?: boolean;
	initialData?: Api.AIChatMessage[];
	staleTime?: number;
}) {
	const userSession = useUserSession();
	return useQuery({
		queryKey: getConversationChatMessageQueryKey(queryParams),
		queryFn: () =>
			userSession.webServiceHelper.callAsync<Api.AIChatMessage[]>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `aiconversation/${queryParams.id}/chatMessage`,
					impersonationContext: queryParams.impersonationContext,
				}),
				'GET'
			),
		enabled: queryParams.enabled,
		initialData: queryParams.initialData,
		staleTime: queryParams.staleTime,
	});
}
